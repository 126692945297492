@if (isCardLayout) {
  <div class="card product-card">
    <!-- Thumbnail -->
    <div
      class="img-container"
      [ngClass]="{ pbna: sharedService.environmentIsPBNA() }"
      (click)="showThumbnailModal()"
    >
      <img
        [src]="productImage()"
        class="card-img-top ga-clic-thumbnail"
        [attr.data-titlega]="productTitleGA()"
      />
    </div>

    @if (sharedService.environmentIsPBNA() && product().images.length > 1) {
      <img
        class="multi-image-icon"
        src="assets/images/products/CLICK_FOR_MORE_GRAPHICS_BUTTON__1_.png"
      />
    }

    <!-- Colors & Download -->
    <div class="row">
      @if (productColors().length > 0) {
        @if (isProductColorAvailable()) {
          <div
            style="padding-left: 5px; padding-right: 5px"
            class="col-7 colors"
          >
            <span class="title">Colors: </span>
            @for (productColor of productColors(); track productColor) {
              <div
                class="bubbles ml-2"
                data-toggle="tooltip"
                data-placement="top"
                [title]="productColor?.name"
              >
                <img [src]="productColor?.image" />
              </div>
            }
          </div>
        } @else {
          <div class="col colors">
            <span class="title">Colors: NA</span>
          </div>
        }
      } @else if (sharedService.environmentIsPBNA()) {
        <div class="col colors">
          <span class="title">&nbsp;</span>
        </div>
      }

      @if (!isPrint && !sharedService.environmentIsPBNA()) {
        <div class="col download">
          <!-- <button
            type="button"
            class="btn btn-2 btn-outline"
            (click)="download()"
          >
            Download
            <img src="assets/images/products/download.PNG" />
          </button> -->
          @if (product().images.length > 0) {
            @if (sharedService.environmentIsPBNA()) {
              <button
                mat-flat-button
                type="button"
                class="download-btn-grid"
                (click)="download()"
              >
                Download
                <mat-icon iconPositionEnd>download</mat-icon>
              </button>
            } @else {
              <button
                mat-stroked-button
                type="button"
                class="download-btn-grid"
                (click)="download()"
              >
                Download
                <mat-icon iconPositionEnd>download</mat-icon>
              </button>
            }
          }
        </div>
      }
    </div>

    <!-- EQI -->
    <div class="row">
      <div class="col mx-2 text eqi">
        {{
          product()
            | eqi
              : (sharedService.environmentIsPBNA() ? "SAP / POLR #: " : "")
              : true
        }}
      </div>
    </div>

    <!-- Name -->
    <div class="row">
      <div class="col mx-2 text name" [innerHTML]="product().name"></div>
    </div>

    <!-- Price -->
    @if (sharedService.environmentIsPBNA()) {
      <div class="row">
        <div class="col mx-2 text price">{{ product().properties.price }}</div>
      </div>
    }

    <!-- Dimensions -->
    <div class="row">
      <div class="col mx-2 text dimensions">
        @if (dimensions()) {
          <span>{{ dimensions() }}</span>
        } @else {
          <span>&nbsp;</span>
        }
      </div>
    </div>

    @if (!sharedService.environmentIsPBNA()) {
      <!-- UOM -->
      @if (product().uom) {
        <div class="row">
          <div class="col mx-2 text">UOM: {{ product().uom }}</div>
        </div>
      }

      <!-- Notes -->
      <div class="row">
        <div class="col mx-2 text notes">
          <div [innerHTML]="notes()"></div>
        </div>
      </div>
    }

    <!-- Bugs -->
    <div class="row mx-3 pt-1 pl-2">
      @for (bug of productBugs(); track bug) {
        <div
          class="col-20 badge"
          data-toggle="tooltip"
          data-placement="top"
          [title]="bug.name"
        >
          <button
            class="btn p-0 ga-clic-bug"
            (click)="navigateToBugId(bug.id)"
            [attr.data-titlega]="bug.name"
          >
            <img
              [src]="bug.image"
              class="ga-clic-bug"
              [ngClass]="{ pbna: sharedService.environmentIsPBNA() }"
              [attr.data-titlega]="bug.name"
            />
          </button>
        </div>
      }
    </div>
  </div>
} @else {
  <div class="card product-list row">
    <!-- Thumbnail -->
    <div class="col-1 img-container" (click)="showThumbnailModal()">
      <img [src]="productImage()" class="card-img-top" />
    </div>

    <!-- EQI & Name-->
    <div class="col-2 equi-name-container">
      @if (!sharedService.environmentIsPBNA()) {
        <div class="text eqi">{{ product() | eqi: "" : true }}</div>
      }

      <div class="text name" [innerHTML]="product().name"></div>
      @if (sharedService.environmentIsPBNA()) {
        <div class="text name">{{ product().properties.price }}</div>
      }
    </div>

    <!-- Notes -->
    <div class="col-3 text notes">
      <div [innerHTML]="notes()"></div>
    </div>

    <!-- Dimensions -->
    <div class="col-2 equi-dimensions-container">
      <div class="mx-2 text dimensions">
        @if (dimensions()) {
          <span>{{ dimensions() }}</span>
        } @else {
          <span>&nbsp;</span>
        }
      </div>

      @if (product().uom) {
        <div class="mx-2 text">UOM: {{ product().uom }}</div>
      }
    </div>

    <!-- Colors -->
    @if (productColors().length > 0) {
      @if (isProductColorAvailable()) {
        <div class="col-1 colors">
          <div class="colors-container">
            @for (productColor of productColors(); track productColor) {
              <div
                class="bubbles ml-2"
                data-toggle="tooltip"
                data-placement="top"
                [title]="productColor?.name"
              >
                <img [src]="productColor?.image" />
              </div>
            }
          </div>
        </div>
      } @else {
        <div class="col-1 colors">
          <span class="title">Colors: NA</span>
        </div>
      }
    } @else if (sharedService.environmentIsPBNA()) {
      <div class="col-1 colors">
        <span class="title">&nbsp;</span>
      </div>
    }

    <!-- Bugs -->
    <div class="col-2 bugs">
      @for (bug of productBugs(); track bug) {
        <div
          class="badge"
          data-toggle="tooltip"
          data-placement="top"
          [title]="bug.name"
        >
          <button
            class="btn p-0 ga-clic-bug"
            (click)="navigateToBugId(bug.id)"
            [attr.data-titlega]="bug.name"
          >
            <img
              [src]="bug.image"
              class="ga-clic-bug"
              [ngClass]="{ pbna: sharedService.environmentIsPBNA() }"
              [attr.data-titlega]="bug.name"
            />
          </button>
        </div>
      }
    </div>

    <!-- Download -->
    @if (!isPrint) {
      <div class="col-1 download">
        <!-- <button
          type="button"
          class="btn btn-2 btn-outline"
          (click)="download()"
        >
          Download
          <img
            [src]="
              sharedService.environmentIsPBNA()
                ? 'assets/images/products/download_pbna.png'
                : 'assets/images/products/download.PNG'
            "
          />
        </button> -->
        @if (product().images.length > 0) {
          @if (sharedService.environmentIsPBNA()) {
            <button
              mat-flat-button
              type="button"
              class="download-btn-grid"
              (click)="download()"
            >
              Download
              <mat-icon iconPositionEnd>download</mat-icon>
            </button>
          } @else {
            <button
              mat-stroked-button
              type="button"
              class="download-btn-grid"
              (click)="download()"
            >
              Download
              <mat-icon iconPositionEnd>download</mat-icon>
            </button>
          }
        }
      </div>
    }
  </div>

  <div class="card product-list-mobile mobile row">
    <!-- Thumbnail & Colors -->
    <div class="col-3">
      <div class="img-container" (click)="showThumbnailModal()">
        <img [src]="productImage()" class="card-img-top" />
      </div>

      @if (productColors().length > 0) {
        @if (isProductColorAvailable()) {
          <div class="colors">
            <div class="colors-container">
              @for (productColor of productColors(); track productColor) {
                <div
                  class="bubbles ml-2"
                  data-toggle="tooltip"
                  data-placement="top"
                  [title]="productColor?.name"
                >
                  <img [src]="productColor?.image" />
                </div>
              }
            </div>
          </div>
        } @else {
          <div class="colors">
            <span class="title">Colors: NA</span>
          </div>
        }
      }
    </div>

    <!-- EQI & Bugs -->
    <div class="col-3">
      <div class="equi-name-container">
        @if (!sharedService.environmentIsPBNA()) {
          <div class="text eqi">{{ product() | eqi: "" : true }}</div>
        }

        <div class="text name" [innerHTML]="product().name"></div>
      </div>

      <div class="bugs">
        @for (bug of productBugs(); track bug) {
          <div
            class="badge"
            data-toggle="tooltip"
            data-placement="top"
            [title]="bug.name"
          >
            <button
              class="btn p-0 ga-clic-bug"
              (click)="navigateToBugId(bug.id)"
              [attr.data-titlega]="bug.name"
            >
              <img
                [src]="bug.image"
                class="ga-clic-bug"
                [ngClass]="{ pbna: sharedService.environmentIsPBNA() }"
                [attr.data-titlega]="bug.name"
              />
            </button>
          </div>
        }
      </div>
    </div>

    <!-- Notes, Dimensions, UOM & Download -->
    <div class="col-6 product-details">
      <div class="text notes">
        <div [innerHTML]="notes()"></div>
      </div>

      <div class="equi-dimensions-container">
        <div class="text dimensions">
          @if (dimensions()) {
            <span>{{ dimensions() }}</span>
          } @else {
            <span>&nbsp;</span>
          }
        </div>

        @if (product().uom) {
          <div class="text">UOM: {{ product().uom }}</div>
        }
      </div>

      @if (!isPrint) {
        <div class="download">
          <!-- <button
            type="button"
            class="btn btn-2 btn-outline"
            (click)="download()"
          >
            Download
            <img
              [src]="
                sharedService.environmentIsPBNA()
                  ? 'assets/images/products/download_pbna.png'
                  : 'assets/images/products/download.PNG'
              "
            />
          </button> -->
          @if (product().images.length > 0) {
            @if (sharedService.environmentIsPBNA()) {
              <button
                mat-flat-button
                type="button"
                class="download-btn-grid"
                (click)="download()"
              >
                Download
                <mat-icon iconPositionEnd>download</mat-icon>
              </button>
            } @else {
              <button
                mat-stroked-button
                type="button"
                class="download-btn-grid"
                (click)="download()"
              >
                Download
                <mat-icon iconPositionEnd>download</mat-icon>
              </button>
            }
          }
        </div>
      }
    </div>
  </div>
}
