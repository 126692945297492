import { inject, Pipe, PipeTransform } from "@angular/core";
import { Product } from "../types/product.type";
import { environment } from "src/environments/environment";
import { BugsService } from "../services/bugs.service";

@Pipe({
  name: "eqi",
  standalone: true,
})
export class EqiPipe implements PipeTransform {
  private _bugsService = inject(BugsService);

  transform(
    product: Product | undefined,
    prefix: string = "",
    spaceUndefined: boolean = false,
  ): string | undefined {
    let eqiText: string | undefined;
    const environmentId = environment.environmentId;
    if (environmentId !== "pbna") {
      eqiText =
        product &&
        product.colors.length > 0 &&
        product.colors[0].fullEqi !== undefined
          ? product?.eqi
          : undefined;

      // For MCOE, hide the EQI if starts with 900 or has the bug "Made To Order"
      if (environmentId === "mcoe") {
        if (eqiText?.startsWith("900")) {
          eqiText = undefined;
        } else {
          const madeToOrderBug = this._bugsService.madeToOrderBug();
          if (madeToOrderBug && product?.bugs.includes(madeToOrderBug.id)) {
            eqiText = undefined;
          }
        }
      }
    } else {
      eqiText = product?.eqi;
    }

    if (eqiText !== undefined && prefix.length > 0) {
      eqiText = `${prefix}${eqiText}`;
    }

    if (eqiText === undefined && spaceUndefined) {
      eqiText = "\u00A0";
    }

    return eqiText;
  }
}
